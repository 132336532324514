import { ActionContext } from "vuex"
import { AxiosResponse } from "axios"
import axiosApi from "@/services/axiosApi"
import {SurveyItemData} from "@/types/SurveyItems";
import {SurveyItemsState} from "@/interfaces/SurveyItemsInterface";

export const surveyItems = {
    state: (): SurveyItemsState => ({
        surveyItems: [],
        statusCode: null
    }),
    getters: {
        GET_SURVEY_ITEMS: (state: SurveyItemsState): SurveyItemData[] => {
            return state.surveyItems;
        },
        GET_SURVEY_ITEM_STATUS_CODE: (state: SurveyItemsState): SurveyItemsState['statusCode'] => {
            return state.statusCode;
        }
    },
    mutations: {
        SET_SURVEY_ITEMS: (state: SurveyItemsState, data: SurveyItemData[]): void => {
            state.surveyItems = data;
        },
        GET_SURVEY_ITEM_STATUS_CODE:(state: SurveyItemsState, status: number)=>{
            state.statusCode = status
        }
    },
    actions: {
        async getSurveyItemsAll({ commit }: ActionContext<SurveyItemsState, any>, companyId: string): Promise<void> {
            try {
                const response: AxiosResponse<SurveyItemData[]> = await axiosApi.get(`/admin/survey-items/${companyId}`);
                console.log('response')
                console.log(response)
                commit('SET_SURVEY_ITEMS', response.data)
            } catch (e) {
                console.error('Error fetching survey items data:', e);
            }
        },
        async updateSurveyItems({ commit }: ActionContext<SurveyItemsState, any>, {companyBranch, survey_item_ids}: {companyBranch: string, survey_item_ids: string[]}): Promise<void> {
            try {
                const response: AxiosResponse<SurveyItemData[]> = await axiosApi.put(`/admin/survey/${companyBranch}?_method=PUT`, {survey_item_ids, companyBranch});
                commit('SET_SURVEY_ITEMS', response.data)
                commit('GET_SURVEY_ITEM_STATUS_CODE', response.status)
            } catch (e) {
                console.error('Error fetching survey items data:', e);
            }
        }
    }
}
