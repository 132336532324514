import axiosApi from "../services/axiosApi"
import { Commit } from "vuex"
import axios from "@/services/axiosApi"

type User = {
    id: string,
    email: string,
    name: string,
    photo: string
}

type Token = {
    access_token: string
    expires_in: number
    token_type: string
};

type Credentials = {
    token: Token | null,
    user: User | null
};

type successMessage = string
type errorMessage = string

type State = {
    credentials: Credentials,
    successMessage: successMessage,
    userHasInteraction: boolean,
    errorMessage: errorMessage
};


export const adminUser = {
    namespaced: true,
    state: (): State => ({
        credentials: {
            token: null,
            user: null,
        },
        userHasInteraction: false,
        successMessage: '',
        errorMessage: ''
    }),
    getters: {
        getUserId: (state: Credentials) => state.user ? state.user.id : null,
        getUserHasInteraction: (state: State) => state.userHasInteraction,
        getUser: (state: Credentials) => state.user ?? null,
        getSuccessMessage: (state: State) => state.successMessage
    },
    mutations: {
        setToken: (state: Credentials, responseData: Token) => {
            state.token = responseData
        },
        resetToken (state: Credentials) {
            state.token = null
        },
        setUser(state: Credentials, responseData: User | null) {
            state.user = responseData
        },
        resetUser (state: Credentials) {
            state.user = null
        },
        setSuccessMessage (state: State, responseData: any) {
            state.successMessage = responseData?.statusText || ''
        },
        setUserHasInteraction(state: State) {
            state.userHasInteraction = true
        }
    },
    actions: {
        async getUser ({commit}: { commit: (...args: any[]) => void }) {
            await axiosApi.post('admin/authUser')
                .then(res => {
                    commit('setUser', res.data)
                })
                .catch(err => err)
        },
        async changePassword({ commit }: { commit: Commit }, data: { payload: object }): Promise<void> {
            try {
                const result = await axiosApi.post(`/admin/settings/change-password`, data)
                commit('setSuccessMessage', result)
            } catch (error: any) {
                console.error('error ', error)
            }
        },
        login: async ({ commit }: { commit: (...args: any[]) => void }, payload: {values:object}) => {
            return await axios.post('admin/login', payload.values)
        },
        logout: async ({ commit }: { commit: (...args: any[]) => void }) => {
            return await axiosApi.post('admin/logout')
        },
        trackUserInteraction({ commit }: { commit: (...args: any[]) => void }) {
            commit('setUserHasInteraction', true);
        },
    }
}
