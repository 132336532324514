import axiosApi from "../services/axiosApi"
import {SurveyItemsResponse} from "@/types/SurveyItemsResponse";
import {SurveyDataResponse} from "@/types/SurveyDataResponse";

type OrderSurveyState = {
    surveyData: SurveyDataResponse|null,
    surveyItems: any|null
}

export const orderSurvey = {
    namespaced: true,
    state: (): OrderSurveyState => ({
        surveyItems : [{'id': 'service', name: 'Service'},{'id': 'meal', name: 'Meal'}],
        surveyData: null
    }),
    getters: {
        getSurveyItems: (state: OrderSurveyState): SurveyItemsResponse|null => {
            return state.surveyItems;
        },
        getSurveyData: (state: OrderSurveyState): SurveyDataResponse|null => {
            return state.surveyData;
        },
        getCurrentPage: (state: OrderSurveyState): string|number|null => {
            return state.surveyData?.meta?.current_page || null;
        },
    },
    mutations: {
        setSurveyItems: (state: OrderSurveyState, data: any): void => {
            state.surveyItems = data
        },
        setSurveyData: (state: OrderSurveyState, data: any): void => {
            state.surveyData = data
        },
    },
    actions: {
        async getSurveyData ({commit}: { commit: (...args: any[]) => void }, payload : {id: string, page: string}) {
            await axiosApi.get(`admin/survey/order/${payload.id}?page=${payload.page}`)
                .then(res => {
                    commit('setSurveyData', res.data)
                })
                .catch(err => err)
        }
    }
}
