<script setup lang="ts">
import { h } from 'vue';
import eventBus from "@/services/eventBus";
import {useRouter} from "vue-router";
import {toast} from "vue3-toastify";
import ToastMessage from '@/components/ToastMessage.vue'
import LogoIcon from "@/components/LogoIcon.vue";
import store from '@/store'

const router = useRouter()
const currentRoute = router.currentRoute

eventBus.on('notification', function (notification){
  if(currentRoute.value.name !== 'chat') {
    play()
    const message = notification.message
    const title = notification.title
    const chat = notification.chat_id
    toast( ({ closeToast, toastProps }) => h(ToastMessage, { closeToast, toastProps, message, title, chat }),
      {
        type: toast.TYPE.INFO,
        closeOnClick: false,
        autoClose: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        icon: LogoIcon
      })
  }
})

const play = () => {
  const player: HTMLAudioElement | null = document.querySelector('#player')
  if (player && !player.muted && store.getters['adminUser/getUserHasInteraction']) {
    player.muted = window.muted
    player.play()
  }
}
</script>

<style >
/** Used to position the icon **/
.Toastify__toast-icon {
  width: 40px;
}
</style>
