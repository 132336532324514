import router from "@/router"
import { showSwalModal } from "@/utils/ModalSwal"

const key = 'token_access_token'

const addAuthToken = (data: any) => {
    localStorage.setItem(key, JSON.stringify(data.auth))
    localStorage.setItem('auth_user_id', data.user.id)
}
const initUserNotifications = () => {
    localStorage.setItem("chat-muted", JSON.stringify(true));
}

const isModalShown = (): boolean => {
    return !!(window as any).showModal;
}

const showSessionExpiredModal = (): Promise<any> => {
    return showSwalModal({
        title: 'Session has expired',
        confirmButtonText: 'OK',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
    })
}

const handleModalConfirmation = ({ value }: { value: boolean }) => {
    if (value) {
        removeTokenFromStorage()
        router.push('/login')
    }
}

const removeAuthToken = (): void => {
    if (!isModalShown()) {
        showSessionExpiredModal().then(handleModalConfirmation)
    }

    (window as any).showModal = true
}

const removeTokenFromStorage = () => {
    localStorage.removeItem(key)
    localStorage.removeItem('auth_user_id')
}


const getAccessToken = () :string|null => {
    const tokenJson = localStorage.getItem(key)
    return tokenJson ? JSON.parse(tokenJson).access_token : null
}

const getUserId = () :string|null => {
    return localStorage.getItem('auth_user_id')
}

export {
    addAuthToken,
    initUserNotifications,
    removeAuthToken,
    getAccessToken,
    removeTokenFromStorage,
    getUserId
}
