import axiosApi from "@/services/axiosApi"
import {AllOrderData, ApiResponse, Commit, OrderState, PendingOrderData} from "@/interfaces/OrderInterface"
import moment from "moment";

export const orders = {
    named: true,
    state: (): OrderState => ({
        orders: {
            pending: [],
            all: []
        },
        pending_order: {},
        response_status_code: null,
        pagination: {
            perPage: null,
            currentPage: null,
            totalPages: null
        },
        stats: {
            count: null,
            canceled: null,
            sum: null,
            currency: null,
        },
        isLoading: true,
    }),
    getters: {
        GET_PENDING_ORDERS: (state: OrderState): Array<PendingOrderData> => {
            return state.orders.pending;
        },
        GET_PENDING_ORDER: (state: OrderState): Record<string, PendingOrderData> => {
            return state.pending_order;
        },
        GET_ORDER_RESPONSE_STATUS_CODE: (state: OrderState): number|undefined|null => {
            return state.response_status_code;
        },
        GET_ORDERS: (state: OrderState): Array<AllOrderData> => {
            return state.orders.all;
        },
        getPagination: (state: OrderState): any => {
            return state.pagination
        },
        getStats: (state: OrderState): any => {
            return state.stats
        },
        getIsLoading: (state: OrderState): boolean => {
            return state.isLoading
        },
    },
    mutations: {
        SET_PENDING_ORDERS: (state: OrderState, response: Array<PendingOrderData>): void => {
            state.orders.pending = response;
        },
        SET_PENDING_ORDER: (state: OrderState, response: Record<string, PendingOrderData>): void => {
            state.pending_order = response;
        },
        SET_ORDER_RESPONSE_STATUS_CODE: (state: OrderState, statusCode: number): void => {
            state.response_status_code = statusCode;
        },
        SET_ORDERS: (state: OrderState, response: Array<AllOrderData>): void => {
            state.orders.all = response;
        },
        addOrder: (state: OrderState, data: PendingOrderData): void => {
            data.created_at = data.sent_date
            moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
            state.orders.pending.push(data);
        },
        setPaginationData: (state: OrderState, fetchedOrders: any): void => {
            state.pagination = {
                perPage: fetchedOrders.per_page as number,
                currentPage: fetchedOrders.current_page as number || 1,
                totalPages: Math.ceil(Number(fetchedOrders.total)/ Number(fetchedOrders.per_page))
            }
        },
        setStats: (state: OrderState, fetchedOrders: any): void => {
            state.stats = {
                count: fetchedOrders.total || 0,
                canceled: fetchedOrders.total_canceled ?? 0,
                sum: fetchedOrders.total_sum || 0,
                currency: fetchedOrders.currency,
            }
        },
        setIsLoading: (state: OrderState, {value} : {value: boolean}): void => {
            state.isLoading = value;
        },
    },
    actions: {
        async getPendingOrders(
            { commit }: { commit: Commit },
            companyBranchId: string
        ): Promise<void> {
            try {
                const response = await axiosApi.get<ApiResponse>(`admin/orders/pending/${companyBranchId}`);
                commit('SET_PENDING_ORDERS', response?.data?.data);
            } catch (error) {
                console.error(error);
            }
        },
        async getPendingOrder(
            { commit }: { commit: Commit },
            orderId: string
        ): Promise<void> {
            try {
                const response = await axiosApi.get<ApiResponse>(`admin/orders/${orderId}`);
                commit('SET_PENDING_ORDER', response?.data?.data);
            } catch (error) {
                console.error(error);
            }
        },
        async acceptOrder(
            { commit }: { commit: Commit },
            orderId: string
        ): Promise<void> {
            try {
                const response = await axiosApi.post<ApiResponse>(`admin/orders/${orderId}/accept`);
                commit('SET_ORDER_RESPONSE_STATUS_CODE', response?.status as any)
            } catch (error) {
                console.error(error);
            }
        },
        async getOrders(
            { commit }: { commit: Commit },
            requestData: any
        ): Promise<void> {
            commit('setIsLoading', {value:true});

            try {
                const response = await axiosApi.post<ApiResponse>(`admin/orders/accepted/${requestData.company_branch_id}?page=${requestData.page}&date_from=${requestData.date_from}&date_to=${requestData.date_to}`);
                commit('SET_ORDERS', response?.data.data);
                commit('setPaginationData', response?.data);
                commit('setStats', response?.data);
                commit('setIsLoading', {value:false});

            } catch (error) {
                console.error(error);
                commit('setIsLoading', {value:false});
            }
        }
    }
};
